import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-message',
  templateUrl: './card-message.component.html',
  styleUrls: ['./card-message.component.scss']
})
export class CardMessageComponent {
  @Input() message: string;
  @Input() showErrorMessage: boolean = false;
  @Input() errorMessage: string = "Contact your system administrator.";
  @Input() showFooterMessage: boolean = false;
  @Input() footerMessage: string = "";
  @Input() showProgressBar: boolean = false;
  @Input() showInput: boolean = false;
  @Input() inputMessage: string = "";
  @Input() submitMessage: string = "";
  @Output() submitClick = new EventEmitter<string>();
  inputValue: string;
  
  onSubmitClick(){
    this.submitClick.emit(this.inputValue)
    this.inputValue = "";
  }
}
