<div class="container"> 
  <div class="row mt-5 mb-5">
    <div class="d-flex justify-content-center">
      <img
        class="perform-logo"
        src="../../../../assets/images/perform-havrion-logo.png"
        alt="Perform Logo"
        border="0"
      />
    </div>
  </div>
  <div class="row h-100 pt-4">
    <div class="d-flex justify-content-center">
      <app-card-message  
        [showInput]="showInput"      
        [showProgressBar]="showProgressBar"
        [showErrorMessage]="showErrorMessage"
        [showFooterMessage]="showFooterMessage"
        [message]="message"
        [footerMessage]="footerMessage"
        [inputMessage]="inputMessage"
        [submitMessage]="submitMessage"
        (submitClick)="onSubmitClick($event)"
      ></app-card-message>
    </div>
  </div>
</div>

