import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, catchError, retry } from 'rxjs';
import { WebPlayerSharedConfig } from '../interfaces/webPlayerShared.interfaces';
import { handleError } from '../utils/generic-utils';


@Injectable({
  providedIn: 'root'
})
export class WebPlayerSharedService {
  endpoint: string = environment.performTVApi.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    responseType: 'json'
  });
  retry = 0;
  WEB_PLAYER_MAIN_CONFIG = "WEB_PLAYER_MAIN_CONFIG"
  WEB_PLAYER_AUTH_TOKEN = "WEB_PLAYER_AUTH_TOKEN"
  WEB_PLAYER_CODE = "WEB_PLAYER_CODE"
  WEB_PLAYER_URI = "WEB_PLAYER_URI"

  constructor(private http: HttpClient) {}

  activateDevice(code: string) {
    const api = `${this.endpoint}/web_player/activate?activation_code=${code}`;
    return this.http
      .get(api, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }


  validateDevice(token: string, payload: any) {
    const api = `${this.endpoint}/web_player/verification`;
    let newHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'json',
      'Authorization': token,
    });
    return this.http
      .post(api, payload, { headers: newHeaders, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  saveToken(token: string){
    localStorage.setItem(this.WEB_PLAYER_AUTH_TOKEN, token);
  }

  saveUri(uri: string){
    localStorage.setItem(this.WEB_PLAYER_URI, uri);
  }

  savePlayerConfig(localConfig: WebPlayerSharedConfig){
    localStorage.setItem(this.WEB_PLAYER_MAIN_CONFIG, JSON.stringify(localConfig));
  }

  saveCode(code: string) {
    localStorage.setItem(this.WEB_PLAYER_CODE, code);
  }

  getToken(){
   let token = localStorage.getItem(this.WEB_PLAYER_AUTH_TOKEN);
   return token;
  }

  getUri(){
    let uri = localStorage.getItem(this.WEB_PLAYER_URI);
    return uri;
  }

  getPlayerConfig():WebPlayerSharedConfig | undefined{
    let config = localStorage.getItem(this.WEB_PLAYER_MAIN_CONFIG);
    return JSON.parse(config);
  }

  getCode(){
    let code = localStorage.getItem(this.WEB_PLAYER_CODE);
    return code;
  }

  deleteSavedConfig(){
    localStorage.clear();
  }

}
