import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';


const routes: Routes = [
  {
    path: "watch/:uri",
    loadChildren: () =>
      import(
        './core/components/web-player/web-player.module'
      ).then((m) => m.WebPlayerModule)
  },
  {
    path:"activate",
    component: AppComponent
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/activate" 
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
