import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SecuredTokenInterceptor } from './core/interceptor/secured-token.interceptor';
import { SecuredMaterialModule } from './core/shared/secured-material/secured-material.module';
import { AuthValidatorComponent } from './core/components/auth-validator/auth-validator.component';
import { CardMessageComponent } from './core/components/card-message/card-message.component';

@NgModule({
  declarations: [
    AppComponent,
    CardMessageComponent,
    AuthValidatorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SecuredMaterialModule,
    HttpClientModule,
  ],
  providers: [ 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecuredTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
