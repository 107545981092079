import { Component, NgZone, OnInit } from '@angular/core';
import { WebPlayerSharedService } from './core/services/web-player-shared.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  validating = false;
  validationError = false; 
  validationMessage = ""; 
  validationDevices = false; 
  validationFooterMessage = ""; 
  showFooterMessage = false;
  showProgressBar: boolean;
  lastCodeUsed:any = null;
  showInput: boolean = false;
  routeSubscription: any;
  fullScreenSize:boolean = false;
  constructor(
    private webPlayerSharedService: WebPlayerSharedService,
    private activatedRoute: ActivatedRoute,
    private ngZone:NgZone,
    private router: Router
  ) {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe({
      next: (params: Params) => {
        let code = params["code"];
        this.initParams(params);
        if(this.lastCodeUsed != code+"" && code){ 
          this.lastCodeUsed = code+"";          
          this.getToken(code+"");            
        }  else {
          this.getToken(null);
        }
      },
      error: (error) => {
        this.showError();
      }
    })
  }

  ngOnInit(): void {
  }

  initParams(params: Params){
    let urlQueryParams = this.getQueryParamsFromOldRoute();       
    if( params["fullScreen"] || urlQueryParams["fullScreen"]){
      this.fullScreenSize = params["fullScreen"] === 'true' || urlQueryParams["fullScreen"] === 'true' ? true :  false;
      this.saveConfig(this.fullScreenSize);   
    }else{
      let config = this.webPlayerSharedService.getPlayerConfig();
      this.fullScreenSize = config?.fullScreenSize || false;
    }
  }

  getToken(code: string) {
    this.validating = true;
    this.showProgressBar = true;

    const oldCode = this.webPlayerSharedService.getCode();

    if ((code && (code !== oldCode || !this.webPlayerSharedService.getToken()))) {
      this.validationMessage = "Authenticating Your Device...";
      this.showInput = false;
      this.webPlayerSharedService.activateDevice(code).subscribe({
        next: (data: any) => {
          this.showInput = false;
          this.validationDevices = true;
          this.showFooterMessage = false; //TODO true if need to show available devices
          this.validationError = false;
          this.showProgressBar = true;
          this.validationMessage = "Redirecting to web player";
          this.validationFooterMessage = "You have " + data.devices + " more available devices";
          this.webPlayerSharedService.saveCode(code);
          this.webPlayerSharedService.saveToken(data.token);
          this.webPlayerSharedService.saveUri(data.uri);
          this.routeSubscription.unsubscribe();
          this.closeVaidation(data.uri);
        },
        error: (error) => {
          this.showProgressBar = false;
          this.validationMessage = "Code not valid, or no more devices availables.";
          this.validationError = true;
          this.showFooterMessage = false;
          this.showInput = true;
        }
      });
    } else {
      let uri = this.webPlayerSharedService.getUri();
      if (this.webPlayerSharedService.getToken() && uri) {
        this.showProgressBar = true;
        this.showFooterMessage = true;
        this.validationFooterMessage = "Device activated";
        this.validationMessage = "Redirecting to web player";
        this.showInput = false;
        this.closeVaidation(uri);
      } else if (!code) {
        this.showError();
      }

    }
  }

  activateCode(code:string){
    this.getToken(code+"");
  }

  showError(){
    this.validating = true;
    this.showProgressBar  = false;
    this.validationMessage = "Invalid code.";
    this.validationError = true;
    this.showFooterMessage = false;
    this.showInput = true;
  }

  closeVaidation(uri: string){ 
    this.ngZone.run(() => {
      setTimeout(() => {     
        const urlDecoded = decodeURIComponent(window.location.pathname);
        const queryParams = urlDecoded.split('?');  
        let query = '';
        if(urlDecoded.includes('watch')){        
          query+='?';
          if(queryParams && queryParams.length > 1 && queryParams[1]){
            query+= queryParams[1]
          }
        }else{
          query = '?fullScreen='+this.fullScreenSize;
        }  
        
        this.validating = false;
        this.router.navigate(['/watch', uri+query]);
      }, 2000);      
    });  
  }

  getQueryParamsFromOldRoute(){
    const urlDecoded = decodeURIComponent(window.location.pathname);
    const queryParams = urlDecoded.split('?');
    const values = {};
    if(queryParams && queryParams.length > 1){
      const queryValues = queryParams[1];
      const query = queryValues.split('&');
      for (let i = 0; i < query.length; i++) {
        const element = query[i];
        const keyValue = element.split('=');
        values[keyValue[0]] = encodeURIComponent(keyValue[1]);
      }
    }
    return values;
  }

  saveConfig(fullScreenSize){
    let config = this.webPlayerSharedService.getPlayerConfig();
    if(!config){
      config = {}
    }
    config.fullScreenSize = fullScreenSize;
    this.webPlayerSharedService.savePlayerConfig(config);
  }
}
