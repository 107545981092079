<div class="card-container"> 
  <mat-card class="card-message container">
    <div class="col">
      <div class="row mt-5 mb-5">
        <img
          class="perform-icon"
          src="../../../../assets/images/perform-havrion-icon.png"
          alt="Perform Logo"
          border="0"
        />
      </div>
      <div class="row mt-5 card-message-text">
        <div class="col text-center">
          <span><b> {{ message }} </b></span> 
        </div>
      </div>
      <div class="row mb-3 card-message-text">
        <div class="col text-center">
          <span *ngIf="showErrorMessage" > <b>{{ errorMessage }} </b></span> 
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col">
          <div class="mt-3 mb-3 progress-bar-container">
            <mat-progress-bar *ngIf="showProgressBar" color="primary" class="progress-bar" mode="indeterminate"></mat-progress-bar>
          </div>
        </div>     
      </div>
      <div *ngIf="showInput" class="row justify-content-center card-input">
        <div class="col">
          <input
            type="text"
            [placeholder]="inputMessage"
            [(ngModel)]="inputValue"
          />
        </div>
        <div class="col-4 col-sm-3">
          <button mat-button (click)="onSubmitClick()">
            {{submitMessage}}
          </button> 
        </div>     
      </div>
      <div class="row card-message-text">
        <div class="col mt-3 mb-5 text-center">
          <span class="footer-message"  *ngIf="showFooterMessage" > {{ footerMessage }} </span> 
        </div>
      </div>
    </div>
  </mat-card>
</div>

