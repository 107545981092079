import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-validator',
  templateUrl: './auth-validator.component.html',
  styleUrls: ['./auth-validator.component.scss']
})
export class AuthValidatorComponent {
  @Input() message: string;
  @Input() showErrorMessage: boolean = false;
  @Input() errorMessage: string;
  @Input() showFooterMessage: boolean = false;
  @Input() footerMessage: string = "";
  @Input() showProgressBar: boolean = false;
  @Input() showInput: boolean = false;
  @Input() inputMessage: string = "Try with a diferent code";
  @Input() submitMessage: string = "Send";
  @Output() submitClick = new EventEmitter<string>();

  constructor(private router: Router) { }

  onSubmitClick(input:string){
    this.submitClick.emit(input)
  }
}
