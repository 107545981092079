import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WebPlayerSharedService } from '../services/web-player-shared.service';

@Injectable()
export class SecuredTokenInterceptor implements HttpInterceptor {
  constructor(
    private webPlayerSharedService: WebPlayerSharedService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let jwt = this.webPlayerSharedService.getToken();
    //Get token from local storage
    const authRequest = request.clone({
      setHeaders: {
        Authorization: request.headers['Authorization'] ?? `Bearer ${jwt}`,
      },
    });
    return next.handle(authRequest);
  }
}
