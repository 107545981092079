<app-auth-validator
    *ngIf="validating" 
    [message]="validationMessage"
    [showErrorMessage]="validationError"
    [showFooterMessage]="showFooterMessage"
    [footerMessage]="validationFooterMessage"
    [showProgressBar]="showProgressBar"
    [showInput]="showInput"
    (submitClick)="activateCode($event)"
></app-auth-validator>

<router-outlet *ngIf="!validating" ></router-outlet>